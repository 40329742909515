<template>
  <card :title="$t('sermons.info')">
    <div class="placeholder-paragraph aligned-centered" v-if="loading">
      <div class="line"></div>
      <div class="line"></div>
    </div>
    <form @submit.prevent="update" @keydown="form.onKeydown($event)" v-if="!loading">
      <alert-success :form="form" :message="$t('sermons.updated')" />

      <sermon-form :form="form"></sermon-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">{{ $t('update') }}</v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import Form from "vform"
import SermonForm from "./form"
import { mapGetters } from "vuex"

export default {
  scrollToTop: false,
  components: {
    "sermon-form": SermonForm
  },
  data: () => ({
    loading: false,
    form: new Form({
      videoUrl: "",
      thumbnailUrl: "",
      thumbnail: null,
      title: "",
      date: "",
      description: "",
      verses: "",
      topics: [],
      speaker_id: "",
      series_id: "",
    })
  }),
  
  async created() {
    let vm = this;
    let sermon_id = this.$route.params.id;
    vm.loading = true;
    
    if (!vm.sermonById(sermon_id)) {
      await this.$store.dispatch('account/fetchSermon', { id: sermon_id });
    }

    this.refresh(vm.sermonById(sermon_id));
  },
  
  computed: {
    ...mapGetters({
      sermonById: 'account/sermonById'
    }),
  },

  methods: {
    refresh(sermon) {
      this.form.keys().forEach(key => {
        this.form[key] = sermon[key];
      });
      this.loading = false;
    },
    
    update() {
      let vm = this;
      this.$store.dispatch('account/updateSermon', { form: this.form, params: { id: this.$route.params.id }}).then(({ sermon }) => {
        // Update the form
        vm.refresh(sermon);
      });   
    }
  }
};
</script>
